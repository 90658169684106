import { takeEvery, call, select, all, put, fork, spawn, takeLatest } from 'redux-saga/effects';
import { requestApi } from '../../request-api';
import { store } from '../../store.js';
import { ReduxUtils } from '@dw/pwa-helpers/redux-utils';
import * as auth from '../auth';
import * as router from '../router';
import * as amplitude from '../../analytics/amplitude.js';
import * as actions from './actions.js';
import * as selectors from './selectors.js';
import get from 'lodash-es/get';
import unset from 'lodash-es/unset';
import isEmpty from 'lodash-es/isEmpty';
import firestoreRedux from '@dreamworld/firestore-redux';
import * as boardTranslations from '../board-translations';
import * as multipleLanguage from '../multiple-language';
import * as user from '../user';

let previousUseCaseBoards;
let unsubscribeLoadUseCaseBoards;
function* loadUseCaseBoards() {
  try {
    const state = yield select();
    const defaultLanguage = multipleLanguage.selectors.defaultLanguage(state) || 'en';
    const currentLanguage = multipleLanguage.selectors.currentLanguage(state);
    const langs = defaultLanguage === currentLanguage ? [defaultLanguage] : [defaultLanguage, currentLanguage];
    firestoreRedux.query('use-case-boards', { where: [['language', 'in', langs]], id: 'use-case-boards' });
    unsubscribeLoadUseCaseBoards && unsubscribeLoadUseCaseBoards();
    unsubscribeLoadUseCaseBoards = ReduxUtils.subscribe(store, `firestore.docs.use-case-boards`, async (boards) => {
      const userIndustry = user.selectors.jobRole(store.getState()) || 'something-else';
      const userIndustryBoards = boards ? Object.values(boards).filter(board => board && board.industries && board.industries.includes(userIndustry)): [];
      const industryBoard = userIndustryBoards && userIndustryBoards[0] && userIndustryBoards[0].boardId || '';
      if(industryBoard) {
        const query = firestoreRedux.getDocById('boards', industryBoard, { requesterId: 'use-case-boards' });
        await query.result;
      }
      const newBoardsIds = boards ? Object.values(boards).map(board => board?.boardId) : [];
      const previousBoardsIds = previousUseCaseBoards ? Object.values(previousUseCaseBoards).map(board => board?.boardId) : [];
      const addedBoards = newBoardsIds.filter(boardId => !previousBoardsIds.includes(boardId)) || [];
      const chunkSize = 10;
      for (let i = 0; i < addedBoards.length; i += chunkSize) {
        const boardIds = addedBoards.slice(i, i + chunkSize);
        try {
          const boardsQuery = firestoreRedux.query('boards', { where: [['id', 'in', boardIds]], requesterId });
          await boardsQuery.result;
        } catch (error) {
          console.error('Error loading use case boards:', error);
        }
      }
      previousUseCaseBoards = boards;
    });
  } catch (error) {
    console.error('Error loading use case boards:', error);
  }
}

function* loadAppData() {
  //Load system language
  firestoreRedux.getDocById(`system-language`, `default`);

  //Load system language
  firestoreRedux.getDocById(`country-wise-lang`, `default`);

  //Load beta users
  firestoreRedux.getDocById('beta-users', 'bus_0');

  firestoreRedux.query('app', { requesterId: 'app-data' });

  yield spawn(loadBoardSettingsData);
  yield spawn(loadJobRoleDetails);

  //Load malicious-urls
  try {
    const maliciousUrlsQuery = firestoreRedux.getDocById(`malicious-urls`, 'default');
    yield maliciousUrlsQuery.result;
    yield spawn(maliciousUrlRedirect);
  } catch (error) {
    console.error('maliciousUrls > loads failed due to this: ', error);
  }
}

/**
 * Load job-role details.
 */
function* loadJobRoleDetails() {
  try {
    firestoreRedux.getDocById('user-property-definitions', 'job-role');
  } catch (error) {
    console.error("app > loadJobRoleDetails > failed due to this: ", error);
  }
}

/**
 * Load default board settings.
 */
function* loadBoardSettingsData() {
  const state = yield select();
  const userId = auth.selectors.currentUserId(state);
  const query = firestoreRedux.getDocById('board-settings', 'bs_0');
  const response = yield query.result;
  const taskBoardId = get(response, 'defaultTemplates.TASKBOARD') || get(response, 'defaultTemplates.en.TASKBOARD');
  
  if(!userId) {
    return;
  }

  if(taskBoardId) {
    yield put(boardTranslations.actions.load(`app-data`, taskBoardId));
  }
}

let previousEventId;
function* routeChangeHandler() {
  yield fork(maliciousUrlRedirect);
  const state = yield select();
  const currentEventId = router.selectors.analyticsEventId(state);
  if (previousEventId !== currentEventId && currentEventId) {
      yield fork(trackAnalyticsEvent, currentEventId);
  }
  previousEventId = currentEventId;
}

function* maliciousUrlRedirect() {
  const state = yield select();
  const pathname = window.location.pathname;
  const query = window.location.search;
  const url = `${pathname}${query}`;
  if(selectors.isMaliciousUrl(state, url)) {
    const redirectUrl = selectors.getMaliciousRedirectUrl(state, url);
    if(redirectUrl) {
      window.location.href = redirectUrl;
    }
  }
}

/**
 * @param {Number} eventId event id.
 * Called server API to gets a analytics-events details from eventId.
 * Track analytics event.
 */
function* trackAnalyticsEvent(eventId) {
  const state = yield select();
  const currentUserId = auth.selectors.currentUserId(state) || null;
  try {
    yield call(removeAnalyticsEventId);
    const eventDetails = yield requestApi(`/import-board/analytics-events/${eventId}`, {method: 'GET'});
    if(!isEmpty(eventDetails)) {
      const eventName = get(eventDetails, 'type');
      const detail = get(eventDetails, 'detail', {});
      const userId = get(detail, 'user_id', currentUserId);
      const memberId = currentUserId || get(detail, 'member_id');
      if(eventName && userId) {
        console.debug("Analytics-events > track. details: ", JSON.stringify(eventDetails));
        unset(detail, 'user_id');
        amplitude.setUserProperties(userId, {}, true);
        amplitude.logEvent(eventName, detail, { outOfSession: true });
        amplitude.setUserProperties(memberId, {}, true);
      } else {
        console.error("Analytics-events > details are not proper.  details: ", JSON.stringify(eventDetails));
      }
    }
  } catch (error) {
    yield call(removeAnalyticsEventId);
  }
}

/**
 * Remove analytics event id and replace url.
 */
function* removeAnalyticsEventId() {
  router.actions.setQueryParams({evt: null});
}

/**
 * Watch router change.
 * Dispatch LOGIN_AND_SIGNUP_PAGE_OPENED, LOGIN_AND_SIGNUP_PAGE_CLOSED actions based on previous & current page.
 */
 function* watchRouter() {
  //If page is already opened, check once.
  yield call(routeChangeHandler);
  yield takeEvery(router.actions.UPDATE_ROUTER, routeChangeHandler);
}

/**
 * Init Saga.
 */
function* appSaga() {
  yield all([
    fork(watchRouter),
    takeEvery(actions.LOAD_APP_DATA, loadAppData),
    takeEvery(auth.actions.UPDATE_AUTH, loadBoardSettingsData),
    takeLatest(actions.LOAD_USE_CASE_BOARDS, loadUseCaseBoards)
  ]);
}

export default appSaga;
